import React, { useEffect } from 'react';
import './header.scss';
import logo from '../../assets/Logo_test.png'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

function Header() { 
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.header .navbar');
      if (window.scrollY > 50) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className="header" id='home'>
      <nav class="navbar">
        <div class="navbar-content">
          <img src={logo} alt="Logo" class="navbar-logo" />
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#contact">Contact</a>
        </div>
        <div class="wallet-adapter-dropdown">
          <WalletMultiButton />
        </div>
      </nav>

      <section className="hero-background">
        <video autoPlay loop muted playsInline className="hero-video">
          <source src="/Site_gif.mp4" type="video/mp4" />
        </video>
      </section>
    </header>
  );
}

export default Header;
