export async function searchAssets(walletAddress) {
  const url = `https://mainnet.helius-rpc.com/?api-key=2c22bc27-4631-4166-ba81-a19bcb9f5fb7`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id: 'my-id',
        method: 'searchAssets',
        params: {
          ownerAddress: walletAddress,
          grouping: ["collection", "9yMDxMypUdAL5qh6iAJHWNFhDRn2csDoaz2kB9bd8TLb"],
          page: 1,
          limit: 1000,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Erro ao buscar ativos: ${response.statusText}`);
    }

    const data = await response.json();
    return data.result; 
  } catch (error) {
    console.error("Erro ao obter ativos da API:", error);
    throw error;
  }
}
