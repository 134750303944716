import React from 'react';
import './footer.scss';
import twitter from '../../assets/imageTwitter.png'
import discord from '../../assets/imageDiscord.png'
import telegram from '../../assets/imageTelegram.png'
import steam from '../../assets/imageSteam.png'

function Footer() {
  return (
    <footer className="footer" id="contact">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} by Junky Bots. All rights reserved.</p>
        <div className="social-links">
          <a href="https://discord.gg/akPSzTRRKs" target="_blank" rel="noopener noreferrer">
            <img src={discord} alt="Discord" />
          </a>
          <a href="https://x.com/junkybots" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="Twitter" />
          </a>
          <a href="https://t.me/dumbots" target="_blank" rel="noopener noreferrer">
              <img src={telegram} alt="Telegram" />
          </a>
          <a href="https://store.steampowered.com/app/2490770/Junkybots" target="_blank" rel="noopener noreferrer">
              <img src={steam} alt="Steam" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
