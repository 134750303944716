import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export const NotificationProvider = ({ children }) => {
  const [message, setMessage] = useState('');

  const triggerNotification = (msg) => {
    setMessage(msg);
    setTimeout(() => setMessage(''), 10000); 
  };

  return (
    <NotificationContext.Provider value={{ triggerNotification }}>
      {children}
      {message && <div className="notification">{message}</div>}
    </NotificationContext.Provider>
  );
};
