import React, { useState } from 'react';
import './main.scss';
import gifFaq from '../../assets/ArtPreviewSite.0b75c21909c8c8a47074.gif';
import imageFaq from '../../assets/Unity_guCMS02ysD.gif';
import imageFaq2 from '../../assets/image-faq-2.png';

function Main() {
  const [showIframe, setShowIframe] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const toggleIframe = () => {
    if (!showIframe) {
      setOpacity(1); 
      setShowIframe(true); 
    } else {
      setOpacity(0); 
      setTimeout(() => setShowIframe(false), 300); 
    }
  };
  
  return (
    <main className="content" id='about'>
      {/* <div className="text-overlay">
        <p className='text-robot'>$DUMBOT LAUNCH ON PUMP.FUN ON MARCH 27TH</p>
        <p className='text-poppins'>JUNKYBOTS is a platform fighting game with roguelike elements where you fight in a multidimensional showdown</p>
      </div> */}
      {!showIframe && (
        <button className="start-button" onClick={toggleIframe}>Start Game</button>
      )}
      {showIframe && (
        <div className="iframe-container">
          <iframe
            className='junky-game'
            frameborder="0" 
            src="https://itch.io/embed-upload/10070156?color=000000"
            style={{ opacity: opacity }}
            width="960" 
            height="560"
            title="Game Junky Bots">
          </iframe>
          <button className="cancel-button" style={{ opacity: opacity }} onClick={toggleIframe}>Stop Game</button>
        </div>
      )}
      <section class="faq-container">
        <div class="faq-item">
          <img src={gifFaq} alt="Descrição da Imagem 1" class="faq-image" />
          <div class="faq-text">
            <h3>The collection</h3>
            <ul className='faq-list'>
              <li>
              Immerse yourself in the world of JUNKYBOTS, a captivating NFT collection showcasing hand-drawn pixel art robots.
              </li>
              <li>
              Each robot represents a share of 0.1% in our game and collection profit.
              </li>
              <li>
              Add us to your Steam wishlist now!
              </li>
            </ul>
          </div>
        </div>
        <div class="faq-item">
          <div class="faq-text">
            <h3>The game</h3>
            <ul className='faq-list'>
              <li>
              Play the thrilling multidimensional universe of the Junkybots game.
              </li>
              <li>
              Engage in epic battles against evil entities that threaten reality, space, and time in a multidimensional showdown.
              </li>
              <li>
              Customize and upgrade your moveset to create incredible combos and synergies.
              </li>
            </ul>
          </div>
          <img src={imageFaq} alt="Descrição da Imagem 2" class="faq-image" />
        </div>
        <div class="faq-item">
          <img src={imageFaq2} alt="Descrição da Imagem 3" class="faq-image " />
          <div class="faq-text">
            <h3>The creator</h3>
            <ul className='faq-list'>
              <li>
              That's me, Victor (Kanart) Sousa, an indie dev who got tired of bad and abusive publisher deals and decided to try his luck on this journey.
              </li>
              <li>
              With over 15 indie titles and almost a decade of experience in game development. I'm now on WEB3
              </li>
              <li>
              JUNKYBOTS embodies my curiosity, blending gaming, blockchain, and community in an experimental Web3 journey.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className='section-faq'>
        <h2>FAQ</h2>
        <div class="faq-row">
          <div class="faq-column">
            <div class="faq-item">
              <h3>Can I test the game?</h3>
              <p>There is a pre-alpha available on the page, but keep in mind that this is not even 1% of the game.</p>
            </div>
            <div className="faq-item">
              <h3>What is Junkybots?</h3>
              <p>Junkybots is an action roguelike game and an NFT collection. Holders receive 0.1% of all the game revenue per NFT they hold.</p>
            </div>
          </div>
          <div class="faq-column">
            <div className="faq-item">
              <h3>Where can I learn more about the project?</h3>
              <p>You can learn more about the project on our Discord and Twitter.</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Main;
