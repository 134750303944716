import React, { useEffect, useRef } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useNotification } from './NotificationProvider';
import { searchAssets } from './getAssetsByOwner';

function NFTCheckerComponent() {
  const { publicKey } = useWallet();
  const { triggerNotification } = useNotification();
  const notificationShown = useRef(false);

  const positiveMessages = [
    "You own a JUNKYBOT 🔥 Thanks for the support! 🤖 I'm wired for joy!",
    "You own a JUNKYBOT 🔥 Thanks for the support! 🤖 I'm charged with happiness!",
    "You own a JUNKYBOT 🔥 Thanks for the support! 🤖 I'm dancing my circuits off!"
  ];

  const negativeMessages = [
    "You don't own any NFTs from our collection. 🤖 My emotional chip just broke with this news :(",
    "You don't own any NFTs from our collection. 🤖 My hard drive is full of sorrow.",
    "You don't own any NFTs from our collection. 🤖 Error 501: Happiness not found."
  ];

  function getRandomMessage(hasNFT) {
    const messages = hasNFT ? positiveMessages : negativeMessages;
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  }

  useEffect(() => {
    const verifyNFTOwnership = async () => {
      if (publicKey && !notificationShown.current) {
        const walletAddress = publicKey.toString();

        try {
          const assets = await searchAssets(walletAddress);
          console.log(assets, 'TEST');

          const message = assets.items && assets.items.length > 0
            ? getRandomMessage(true)  
            : getRandomMessage(false); 

          triggerNotification(message);
          notificationShown.current = true;
        } catch (error) {
          console.error("Erro ao verificar ativos na carteira:", error);
          triggerNotification("Houve um erro ao verificar seus ativos. Por favor, tente novamente.");
          notificationShown.current = true;
        }
      }
    };

    verifyNFTOwnership();
  }, [publicKey, triggerNotification]);

  useEffect(() => {
    if (!publicKey) {
      notificationShown.current = false;  
    }
  }, [publicKey]);

  return null;  
}

export default NFTCheckerComponent;
