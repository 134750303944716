// App.js
import React from 'react';
import './App.scss';
import Header from './components/header/Header';
import Main from './components/main/Main';
import Footer from './components/footer/Footer';
import NFTCheckerComponent from '../src/utils/NFTCheckerComponent';
import { NotificationProvider } from '../src/utils/NotificationProvider';

function App() {
  return (
    <NotificationProvider>
      <div className="App">
        <Header />
        <Main />
        <Footer />
        <NFTCheckerComponent />
      </div>
    </NotificationProvider>
  );
}

export default App;
